import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["link", "menu"]

  toggleActive (e) {
    e.preventDefault()
    e.stopPropagation()

    // if clicked link is already active return null to close it
    const activeTab = e.currentTarget.classList.contains("active") ? null : e.currentTarget.dataset.id

    this.linkTargets.forEach((link) => {
      link.classList.toggle("active", link.dataset.id === activeTab)
    })

    this.menuTargets.forEach((menu) => {
      menu.classList.toggle("active", menu.id === activeTab)
    })
  }

  disableAllMenu () {
    this.linkTargets.forEach((link) => {
      link.classList.remove("active")
    })

    this.menuTargets.forEach((menu) => {
      menu.classList.remove("active")
    })
  }

  clickOutsideHandler (e) {
    this.menuTargets.forEach((menu) => {
      if (!menu.contains(e.target)) {
        this.disableAllMenu()
      }
    })
  }
}
