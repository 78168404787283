import PlacesAutocomplete from "stimulus-places-autocomplete"

export default class extends PlacesAutocomplete {
  connect () {
    super.connect()

    this.boundSetFocusToInput = this.setFocusToInput.bind(this)
    document.addEventListener('location_filter_tab_controller.activeTabChanged', this.boundSetFocusToInput)
    document.addEventListener('location_filter_tab_controller.parentDropdownOpen', this.boundSetFocusToInput)

    this.boundResetAutocomplete = this.resetAutocomplete.bind(this)
    document.addEventListener('location_filter_tab_controller.cleanInactiveTabInputs', this.boundResetAutocomplete)
  }

  disconnect () {
    document.removeEventListener('location_filter_tab_controller.activeTabChanged', this.boundSetFocusToInput)
    document.removeEventListener('location_filter_tab_controller.cleanInactiveTabInputs', this.boundResetAutocomplete)
    document.removeEventListener('location_filter_tab_controller.parentDropdownOpen', this.boundSetFocusToInput)
  }

  setFocusToInput (event) {
    if (event.detail.activeTabName === 'byAddress') {
      // For some reason, we have to set a timeout for the focus to work on this input...
      setTimeout(() => { this.addressTarget.focus() }, 20)
    }
  }

  resetAutocomplete (event) {
    // Clean inputs of google places tab if inactive tab to prevent their submission
    if (event.detail.activeTabName === 'byAddress') return

    this.element.querySelectorAll('input').forEach(input => {
      input.value = ''
    })
  }
}
