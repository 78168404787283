import AutocompleteController from './autocomplete_controller'
export default class extends AutocompleteController {
  static targets = [
    'cityId',
    'placeId',
    'placeType',
    'input',
    'searchInput'
  ]

  connect () {
    super.connect()

    this.boundSetFocusToInput = this.setFocusToInput.bind(this)
    document.addEventListener('location_filter_tab_controller.activeTabChanged', this.boundSetFocusToInput)
    document.addEventListener('location_filter_tab_controller.parentDropdownOpen', this.boundSetFocusToInput)

    this.boundResetAutocomplete = this.resetAutocomplete.bind(this)
    document.addEventListener('location_filter_tab_controller.cleanInactiveTabInputs', this.boundResetAutocomplete)
  }

  disconnect () {
    document.removeEventListener('location_filter_tab_controller.activeTabChanged', this.boundSetFocusToInput)
    document.removeEventListener('location_filter_tab_controller.cleanInactiveTabInputs', this.boundResetAutocomplete)
    document.removeEventListener('location_filter_tab_controller.parentDropdownOpen', this.boundSetFocusToInput)
  }

  setFocusToInput (event) {
    if (event.detail.activeTabName === 'byCity') {
      // For some reason, we have to set a timeout for the focus to work on this input...
      setTimeout(() => { this.inputTarget.focus() }, 20)
    }
  }

  resetAutocomplete (event) {
    // Clean inputs of google places tab if inactive tab to prevent their submission
    if (event.detail.activeTabName === 'byCity') return

    this.element.querySelectorAll('input').forEach(input => {
      input.value = ''
    })
  }

  selectCity (event) {
    event.preventDefault()

    const title = event.currentTarget.textContent
    const value = event.currentTarget.dataset.value
    this.cityIdTarget.value = value
    this.inputTarget.value = title
    document.querySelector('#search-location-label').innerHTML = title
    this.closeResults()
  }
}
