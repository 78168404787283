import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input']

  connect () {
    this.isOpen = false
  }

  clickOutsideHandler (e) {
    if (this.isOpen && !this.element.contains(e.target)) {
      this.element.classList.remove("active")
      this.isOpen = false
      document.dispatchEvent(new CustomEvent('dropdown_controller.dropdownClosed', { detail: { dropdownElement: this.element } }))
    }
  }

  toggleActive () {
    this.element.classList.toggle("active", !this.isOpen)
    this.isOpen = !this.isOpen

    if (!this.isOpen) {
      document.dispatchEvent(new CustomEvent('dropdown_controller.dropdownClosed', { detail: { dropdownElement: this.element } }))
      return
    }

    if (this.hasInputTarget) {
      this.inputTarget.focus()
    } else {
      document.dispatchEvent(new CustomEvent('dropdown_controller.dropdownOpen', { detail: { dropdowElement: this.element } }))
    }
  }
}
