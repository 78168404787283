import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ["button", "content"]

  toggleActive (event) {
    event.preventDefault()
    event.stopPropagation()

    this.buttonTargets.forEach((button) => {
      button.classList.toggle("active", event.currentTarget.dataset.tabName === button.dataset.tabName)
    })

    this.contentTargets.forEach((content) => {
      content.classList.toggle("active", event.currentTarget.dataset.tabName === content.dataset.tabName)
    })
  }
}
