import TabController from './tab_controller'

export default class extends TabController {
  static targets = ['input']

  connect () {
    super.connect()

    this.boundCleanInactiveTabInputs = this.cleanInactiveTabInputs.bind(this)
    document.addEventListener('search_controller.onSubmit', this.boundCleanInactiveTabInputs)

    this.boundParentDropdownOpen = this.parentDropdownOpen.bind(this)
    document.addEventListener('dropdown_controller.dropdownOpen', this.boundParentDropdownOpen)
  }

  disconnect () {
    document.removeEventListener('search_controller.onSubmit', this.boundCleanInactiveTabInputs)
    document.removeEventListener('dropdown_controller.dropdownOpen', this.boundParentDropdownOpen)
  }

  toggleActive (event) {
    const activeTabChanged = !event.currentTarget.classList.contains('active')

    super.toggleActive(event)

    // Dispatch custom event for GooglePlaces and SectorAutocomplete controllers if the active tab has changed
    if (activeTabChanged) {
      document.dispatchEvent(new CustomEvent('location_filter_tab_controller.activeTabChanged', { detail: { activeTabName: this.getActiveTabName() } }))
    }
  }

  getActiveTabName () {
    return this.contentTargets.find(contentTarget => contentTarget.classList.contains('active')).dataset.tabName
  }

  cleanInactiveTabInputs () {
    // Dispatch custom event for GooglePlaces and SectorAutocomplete controllers to clean the location inputs that are inside in the inactive tab
    document.dispatchEvent(new CustomEvent('location_filter_tab_controller.cleanInactiveTabInputs', { detail: { activeTabName: this.getActiveTabName() } }))
  }

  parentDropdownOpen () {
    // Dispatch custom event for GooglePlaces and SectorAutocomplete controllers to set the focus on the active tab input
    document.dispatchEvent(new CustomEvent('location_filter_tab_controller.parentDropdownOpen', { detail: { activeTabName: this.getActiveTabName() } }))
  }
}
