import AutocompleteController from './autocomplete_controller'
import { template } from 'lodash'

export default class extends AutocompleteController {
  static targets = [
    'sectorId',
    'tags',
    'tagTemplate',
    'input'
  ]

  connect () {
    super.connect()

    if (this.hasTagsTarget) {
      this.resourceTemplate = template(this.tagTemplateTarget.innerHTML)
    }

    this.boundUpdateComponent = this.updateComponent.bind(this)
    document.addEventListener('location_filter_tab_controller.activeTabChanged', this.boundUpdateComponent)
    document.addEventListener('location_filter_tab_controller.parentDropdownOpen', this.boundUpdateComponent)

    this.boundResetAutocomplete = this.resetAutocomplete.bind(this)
    document.addEventListener('location_filter_tab_controller.cleanInactiveTabInputs', this.boundResetAutocomplete)
  }

  disconnect () {
    document.removeEventListener('location_filter_tab_controller.activeTabChanged', this.boundUpdateComponent)
    document.removeEventListener('location_filter_tab_controller.cleanInactiveTabInputs', this.boundResetAutocomplete)
    document.removeEventListener('location_filter_tab_controller.parentDropdownOpen', this.boundUpdateComponent)
  }

  selectSector (event) {
    event.preventDefault()

    const title = event.currentTarget.textContent
    const value = event.currentTarget.dataset.value

    if (this.hasTagsTarget) {
      this.addTag(title, 'by_sector_id', value)
    } else {
      this.sectorIdTarget.value = value
    }

    this.inputTarget.value = ''
    this.closeResults()
  }

  addTag (title, attribute, value) {
    const content = this.resourceTemplate({ title, attribute, value })

    this.tagsTarget.insertAdjacentHTML('beforeend', content)
    this.inputTarget.focus()
  }

  removeTag (event) {
    event.stopImmediatePropagation()

    event.currentTarget.remove()
  }

  removeAllTags () {
    this.tagsTarget.querySelectorAll('button').forEach(button => button.remove())
  }

  exceptIds () {
    if (!this.hasTagsTarget) return

    return [...this.sectorIdTargets].map(input => input.value)
  }

  removeResults () {
    this.resultsTarget.innerHTML = ''
  }

  resetAutocomplete (event) {
    // Clean inputs of sector tab if inactive tab to prevent their submission
    if (event.detail.activeTabName === 'bySector') return

    this.removeAllTags()
    this.removeResults()
    this.element.querySelectorAll('input').forEach(input => {
      input.value = ''
    })
  }

  updateComponent (event) {
    if (event.detail.activeTabName === 'bySector') {
      this.inputTarget.focus()
    } else {
      this.removeResults()
    }
  }
}
