/* eslint no-undef:0 */

import RemoteRails from "stimulus-remote-rails"

export default class extends RemoteRails {
  static targets = [
    'minArea',
    'maxArea',
    'minPrice',
    'maxPrice',
    'localisation',
    'contractType',
    'accommodationType',
    'byReference'
  ]

  static values = {
    domTarget: String
  }

  replace (event) {
    const [,, xhr] = event.detail

    if (this.hasDomTargetValue) {
      event.preventDefault()
      event.stopPropagation()

      const resultsContainer = document.querySelector(this.domTargetValue)

      if (resultsContainer) {
        resultsContainer.innerHTML = xhr.response
      }
    } else {
      super.replace(event)
    }
    history.pushState(null, null, xhr.responseURL)
  }

  closeModal () {
    document.querySelector(shbModalSelector).modal.close()
  }

  pushDataLayer () {
    if (!window.dataLayer) return

    window.dataLayer.push({
      event: 'internal_search',
      surface_min: this.hasMinAreaTarget ? this.minAreaTarget.value : undefined,
      surface_max: this.hasMaxAreaTarget ? this.maxAreaTarget.value : undefined,
      prix_min: this.hasMinPriceTarget ? this.minPriceTarget.value : undefined,
      prix_max: this.hasMaxPriceTarget ? this.maxPriceTarget.value : undefined,
      localisation: this.hasLocalisationTarget ? this.localisationTarget.value : undefined,
      contractType: (this.contractTypeTargets.find(radio => radio.checked) || {}).value,
      estateType: (this.accommodationTypeTargets.filter(checkbox => checkbox.checked).map(checkbox => checkbox.value)),
      reference: this.hasByReferenceTarget ? this.byReferenceTarget.value : undefined
    })
  }

  selectAccommodation (event) {
    document.dispatchEvent(new CustomEvent('search_controller.accommodationSelected', { detail: { accommodationId: event.currentTarget.dataset.id } }))
  }

  onSubmit () {
    // Dispatch custom event to clean the location inputs that are inside an inactive tab to prevent their submission
    document.dispatchEvent(new CustomEvent('search_controller.onSubmit'))
  }
}
